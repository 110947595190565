.dropDownProfile{
  position: absolute;
  top: 4.6rem;
  z-index: 20;
  right:0.75%;
  width: 120px;
  padding: 7px;
  border-radius: 8px;
  background-color: #E1E7EC;
  border: 1px solid gray;
}

.dropDownProfile::before{
  content: '';
  position: absolute;
  top: -0.7rem;
  right: 1.1rem;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background-color: #E1E7EC;
  border-left: 1px solid gray;
  border-top: 1px solid gray;
}
.icon-button{
  background-color: #62645e;
  border-radius:50%;
  padding: 3px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}
.icon-button:hover{
  filter:brightness(1.7);
}
.dropdown-button{
  background-color: rgb(39, 58, 94);
  width: 90px;
  
  border-radius:5px;
  padding: 5px;
  margin: 7px;
  display: flex;
  align-items: center;
  
  transition: filter 300ms;
}
.dropdown-button:hover{
  filter:brightness(2);
  cursor: pointer;
}
.text{
  
    color: rgb(255, 255, 255);
    font-size: 17px;
  
  
}